<div class="user-management__icon-box">
    <mat-icon class="user-management__icon-box__icon">
        archive
    </mat-icon>
    <span class="user-management__icon-box__title">Archive
    </span>
</div>


<div class="user-management__content--1">
    <div class="user-management__container">
        <div class="form-container">
            <div class="sec-1">
                <div class="sec-7">
                    <div class="risk-report-button-box">
                        <button class="risk-report-button-box__btn btn--chestnut" mat-raised-button color="primary"
                            (click)="archiveInit()">
                            <mat-icon class="btn-icon">archive</mat-icon>

                            Archive
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-box mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">


                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.id }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Official code">
                        <th mat-header-cell *matHeaderCellDef>Official code</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.official_code}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Short Name">
                        <th mat-header-cell *matHeaderCellDef>Short Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.short_name}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="m">
                                <mat-checkbox class="example-margin"
                                    (change)="checkInit($event, element.id)"></mat-checkbox>

                            </div>
                        </td>
                    </ng-container>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No data found.
                        </td>
                    </tr>
                    <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
    </div>