<section class="section-risk-management-component">
    <div class="risk-management-container">
        <div class="risk-management-content">
            <app-search-init (filters)="filter($event)"> </app-search-init>

            <div class="table-box">
                <div class="table-box mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource">
                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">ID</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span class="text-s-c-linear">{{ row.initiative.id }}</span>
                            </td>
                        </ng-container>

                        <!-- ID Column -->
                        <ng-container matColumnDef="official_code">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">Official code</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span class="text-s-c-linear">{{ row.initiative.official_code }}</span>
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">Name</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span class="text-s-c-linear">{{ row.initiative.name }}</span>
                            </td>
                        </ng-container>

                        <!-- Short Column -->
                        <ng-container matColumnDef="short_name">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">Short Name</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span class="text-s-c-linear">{{ row.initiative.short_name }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="my_role">
                            <th mat-header-cell *matHeaderCellDef>
                              <span class="text-color-linear">My role</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                              <span class="text-s-c-linear">{{ myRoles(row.initiative.roles) }}</span>
                            </td>
                          </ng-container>


                        <!-- Last update on Column -->
                        <ng-container matColumnDef="last_update_at">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">Last update on</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span class="text-s-c-linear">{{
                                    row.initiative.last_update_at | date
                                    }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>
                              <span class="text-color-linear">Current Status</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                              <span class="text-s-c-linear">{{
                                row.initiative.last_submitted_at != null &&
                                row.initiative.last_update_at == row.initiative.last_submitted_at
                                  ? row?.initiative?.latest_submission
                                    ? row?.initiative?.latest_submission?.status
                                    : "Draft"
                                  : "Draft"
                              }}</span>
                            </td>
                          </ng-container>


                        <!-- actions -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-color-linear">Actions</span>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="actions">
                                    <button class="color-icons-table-action" matTooltip="View Submitted Versions"
                                        matTooltipPosition="above" [routerLink]="[row['id'], 'submitted-versions']"
                                        mat-icon-button>
                                        <mat-icon>view_carousel</mat-icon>
                                    </button>

                                    <button class="color-icons-table-action" matTooltip="View Team Members"
                                        matTooltipPosition="above" [routerLink]="[row['id'], 'team-member']"
                                        mat-icon-button>
                                        <mat-icon>group</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8">
                                No results matching({{
                                allfilters?.name ||
                                allfilters?.my_role ||
                                allfilters?.initiative_id ||
                                allfilters?.status
                                }})
                            </td>
                        </tr>
                    </table>
                    <div class="glossary-content-container user-management-paginator-box">
                        <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" [length]="length"
                            [pageIndex]="pageIndex - 1" (page)="pagination($event)"
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>