<!-- <app-admin-navbar></app-admin-navbar>
<router-outlet></router-outlet> -->

<div class="admin-module-box">
  <nav class="admin-module-box__sidebar">
    <div class="sidebar__title-box">
      <p class="sidebar__title-box--title">Admin module</p>
    </div>

    <ul class="side-nav">
      <li>
        <a
          routerLink="/admin/users"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <img
              class="side-nav-icon-box__icon--1"
              src="../../../assets/shared-image/🦆 icon _Users Cog_.svg"
              alt="~/Frontend/images/icon _ icon _Users"
          /></mat-icon>
          <span class="side-nav-icon-box__title--1">User management</span>
        </a>
      </li>

      <li>
        <a
          routerLink="/admin/organizations"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M24 18.714v4.8c0 .288-.192.48-.48.48h-4.8c-.288 0-.48-.192-.48-.48v-4.8c0-.288.192-.48.48-.48h1.92v-1.92h-8.16v1.92h1.92c.288 0 .48.192.48.48v4.8c0 .288-.192.48-.48.48H9.6c-.288 0-.48-.192-.48-.48v-4.8c0-.288.192-.48.48-.48h1.92v-1.92H3.36v1.92h1.92c.288 0 .48.192.48.48v4.8c0 .288-.192.48-.48.48H.48c-.288 0-.48-.192-.48-.48v-4.8c0-.288.192-.48.48-.48H2.4v-2.4c0-.288.192-.48.48-.48h8.64v-1.44h.96v1.44h8.64c.288 0 .48.192.48.48v2.4h1.92c.288 0 .48.192.48.48zm-13.92 4.32h3.84v-3.84h-3.84zm-9.12 0H4.8v-3.84H.96Zm18.24 0h3.84v-3.84H19.2ZM6.24 9.642V3.546c0-.192.096-.336.24-.432L11.76.042a.818.818 0 0 1 .48 0l5.28 3.072c.144.096.24.24.24.432v6.096c0 .144-.096.288-.24.384l-5.28 3.072q-.096.048-.24.048t-.24-.048l-5.28-3.072c-.144-.096-.24-.24-.24-.384Zm10.56-.288V4.362l-4.32 2.496v4.992zm-9.6 0l4.32 2.496V6.858L7.2 4.362Zm.48-5.808L12 5.994l4.32-2.448L12 1.05Z"
              /></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--2">Organizations</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/phases"
          routerLinkActive="side-nav__active"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon--1 size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M19 11q-.95 0-2.025-.537t-2-1.488q-.925-.95-1.462-2.025t-.538-2q0-.425.125-.775t.375-.6q.65-.65 2.638-1.138t3.737-.412q.625.05 1.038.15t.612.3q.175.175.288.537t.162.913q.125 1.725-.35 3.8t-1.15 2.75q-.25.25-.637.388T19 11Zm.875-5q.05-.45.075-.95T20 4q-.5-.025-1.012.013t-1.013.087q.275.2.537.425t.488.45q.25.25.463.5t.412.525ZM19 9q.025-.5-.375-1.238T17.575 6.4q-.625-.625-1.338-1.012T15.026 5q.05.575.425 1.3t.925 1.275q.6.6 1.313.988T19 9Zm2.3 9.7L16.6 14h-10L1.3 8.7l1.4-1.4L7.4 12h10l5.3 5.3l-1.4 1.4ZM8 22v-4q0-.825.588-1.413T10 16h4q.825 0 1.413.588T16 18v4H8Z"
                fill="white"
              /></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--3">Phases</span>
        </a>
      </li>

      <li>
        <a
          routerLink="/admin/periods"
          routerLinkActive="side-nav__active"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon--1 size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M5.075 12H8q.275 0 .525.138t.375.412l1.1 2.2l3.1-6.2q.275-.575.9-.575t.9.575L16.625 12h2.3Q18.55 9.45 16.6 7.725T12 6Q9.35 6 7.4 7.725T5.075 12ZM12 20q2.65 0 4.6-1.725T18.925 14H16q-.275 0-.525-.138t-.375-.412l-1.1-2.2l-3.1 6.2q-.275.575-.9.575t-.9-.575L7.375 14h-2.3q.375 2.55 2.325 4.275T12 20Zm0 2q-1.85 0-3.488-.713T5.65 19.35q-1.225-1.225-1.938-2.863T3 13h2q0 2.9 2.05 4.95T12 20q2.9 0 4.95-2.05T19 13h2q0 1.85-.713 3.488T18.35 19.35q-1.225 1.225-2.863 1.938T12 22Zm-9-9q0-1.85.713-3.488T5.65 6.65q1.225-1.225 2.863-1.938T12 4q1.55 0 2.975.5t2.675 1.45l1.4-1.4l1.4 1.4l-1.4 1.4Q20 8.6 20.5 10.025T21 13h-2q0-2.9-2.05-4.95T12 6Q9.1 6 7.05 8.05T5 13H3ZM9 3V1h6v2H9Zm3 17q-2.9 0-4.95-2.05T5 13q0-2.9 2.05-4.95T12 6q2.9 0 4.95 2.05T19 13q0 2.9-2.05 4.95T12 20Zm0-7Z"
              /></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--4">Periods</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/ipsr"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g
                fill="none"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              >
                <path
                  d="M20 12V5.749a.6.6 0 0 0-.176-.425l-3.148-3.148A.6.6 0 0 0 16.252 2H4.6a.6.6 0 0 0-.6.6v18.8a.6.6 0 0 0 .6.6H13M8 10h8M8 6h4m-4 8h3"
                />
                <path
                  d="M16 2v3.4a.6.6 0 0 0 .6.6H20m-.008 9.125l2.556.649c.266.068.453.31.445.584C22.821 22.116 19.5 23 19.5 23s-3.321-.884-3.493-6.642a.588.588 0 0 1 .445-.584l2.556-.649c.323-.082.661-.082.984 0Z"
                />
              </g></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--5">IPSR</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/parameters-settings"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 256 256"
            >
              <g
                id="galaSettings0"
                fill="none"
                stroke="white"
                stroke-dasharray="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="4"
                stroke-opacity="1"
                stroke-width="16"
              >
                <path
                  id="galaSettings1"
                  d="M 48.000002,16 H 208 c 17.728,0 32,14.272 32,32 v 160 c 0,17.728 -14.272,32 -32,32 H 48.000002 c -17.728,0 -32,-14.272 -32,-32 V 48 c 0,-17.728 14.272,-32 32,-32 z"
                />
                <path
                  id="galaSettings2"
                  d="M 64.000006,64.000001 H 79.999993"
                />
                <path
                  id="galaSettings3"
                  d="m 79.999996,-96.000015 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16.000005 16,16 0 0 1 16,16.000005 z"
                  transform="rotate(90)"
                />
                <path
                  id="galaSettings4"
                  d="m 112.00001,64.000353 79.99997,-3.52e-4"
                />
                <path id="galaSettings5" d="M 191.99998,128 H 176" />
                <path
                  id="galaSettings6"
                  d="m 144,159.99997 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16 16,16 0 0 1 16,16 z"
                  transform="matrix(0 1 1 0 0 0)"
                />
                <path
                  id="galaSettings7"
                  d="M 143.99998,128.00035 64.000006,128"
                />
                <path
                  id="galaSettings8"
                  d="M 64.000006,192.00001 H 79.999993"
                />
                <path
                  id="galaSettings9"
                  d="m 208,-96.000015 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16.000005 16,16 0 0 1 16,16.000005 z"
                  transform="rotate(90)"
                />
                <path
                  id="galaSettingsa"
                  d="m 112.00001,192.00036 79.99997,-3.5e-4"
                />
              </g></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--6">Parameters settings</span>
        </a>
      </li>
      <!-- <li>
        <a
          routerLink="/admin/melia"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="white" stroke-width="1.5">
                <circle cx="12" cy="12" r="10" />
                <circle cx="12" cy="12" r="4" />
                <path d="m15 9l4-4M5 19l4-4m0-6L5 5m14 14l-4-4" />
              </g></svg
          ></mat-icon>
          <span class="side-nav-icon-box__title--7">MELIA</span>
        </a>
      </li> -->
      <!-- <li>
        <a
          routerLink="/admin/Anticipated-year"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon class="side-nav-icon-box__icon size-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 40 40"
            >
              <g fill="white">
                <path
                  d="M36.389 4.962h-5.906a.446.446 0 0 0-.446.446c0 .076.024.144.057.206v3.398a.947.947 0 0 1-.948.948h-1.838a.88.88 0 0 1-.88-.88V5.669a.435.435 0 0 0 .094-.261a.445.445 0 0 0-.446-.446H14.171a.446.446 0 0 0-.446.446c0 .069.019.132.047.191v3.437a.925.925 0 0 1-.925.925h-1.632a.934.934 0 0 1-.934-.934V5.624a.432.432 0 0 0 .063-.216a.445.445 0 0 0-.446-.446H3.675a.993.993 0 0 0-.992.991v29.534c0 .548.445.993.992.993h32.714a.993.993 0 0 0 .991-.993V5.953a.993.993 0 0 0-.991-.991zm0 30.626H3.675a.1.1 0 0 1-.1-.101V13.214h32.913v22.272a.1.1 0 0 1-.099.102z"
                />
                <path
                  d="M12.147 8.944c.246 0 .446-.2.446-.446V2.5a.446.446 0 1 0-.892 0v5.999c0 .246.2.445.446.445zm16.154 0c.246 0 .446-.2.446-.446V2.5a.446.446 0 1 0-.892 0v5.999c0 .246.199.445.446.445z"
                />
                <circle cx="9.842" cy="17.503" r="2.458" />
                <circle cx="16.906" cy="17.503" r="2.458" />
                <circle cx="23.969" cy="17.503" r="2.458" />
                <circle cx="9.842" cy="24.348" r="2.458" />
                <circle cx="16.906" cy="24.348" r="2.458" />
                <circle cx="23.969" cy="24.348" r="2.458" />
                <circle cx="9.842" cy="31.193" r="2.458" />
                <circle cx="16.906" cy="31.193" r="2.458" />
                <circle cx="23.969" cy="31.193" r="2.458" />
                <circle cx="30.867" cy="17.503" r="2.458" />
                <circle cx="30.867" cy="24.348" r="2.458" />
                <circle cx="30.867" cy="31.193" r="2.458" />
              </g>
            </svg>
          </mat-icon>
          <span class="side-nav-icon-box__title--7">Anticipated Year</span>
        </a>
      </li> -->
      <li>
        <a
          routerLink="/admin/popover-management"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon
            class="side-nav-icon-box__icon size-icon"
            style="color: white"
          >
            help
          </mat-icon>
          <span class="side-nav-icon-box__title--2">Popover management</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/emails"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon
            class="side-nav-icon-box__icon size-icon"
            style="color: white"
          >
            email
          </mat-icon>
          <span class="side-nav-icon-box__title--2">Emails management</span>
        </a>
      </li>

      <li>
        <a
          routerLink="/admin/track-porbs"
          routerLinkActive="side-nav__active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="side-nav__item side-nav__link side-nav__items"
        >
          <mat-icon
            class="side-nav-icon-box__icon size-icon"
            style="color: white"
          >
            <span class="material-symbols-outlined"> track_changes </span>
          </mat-icon>
          <span class="side-nav-icon-box__title--2">Track PORBs</span>
        </a>
      </li>

    </ul>
  </nav>

  <div class="admin-module-box__content">
    <router-outlet></router-outlet>
  </div>
</div>
