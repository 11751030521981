<div class="section-risk-report-component">
    <div class="team-members-container">
        <nav class="risk-nav-main">
            <ul class="risk-nav-list">
                <li>
                    <a class="risk-nav-link" routerLink="/">Initiatives</a>
                </li>
                <li>
                    <a routerLink="./" class="risk-nav-link">></a>
                </li>
                <li>
                    <span class="line">&nbsp;</span>{{ data?.initiative?.official_code }}
                </li>
                <li>
                    <a routerLink="./" class="risk-nav-link">></a>
                </li>
                <li>
                    <a routerLink="./" class="risk-nav-link">Team members</a>
                </li>
            </ul>
        </nav>
        <div class="team-member-content-box">
            <div class="team-members-text-box">
                <p class="team-members-text-box__text">Team members</p>
            </div>



            <div class="table-box mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="User Name">
                        <th mat-header-cell *matHeaderCellDef>User Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element["User Name"] }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element?.user?.email || element.email }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="User">
                        <th mat-header-cell *matHeaderCellDef>User name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element?.user?.full_name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Creation Date">
                        <th mat-header-cell *matHeaderCellDef>Creation Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.createdAt | date : "MMMM d, y" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Role">
                        <th mat-header-cell *matHeaderCellDef>Role</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.role }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="organizations">
                        <th mat-header-cell *matHeaderCellDef>Granted access</th>
                        <td mat-cell *matCellDef="let element">
                            {{ join(element.organizations) || "Full access" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user_id ? "Active" : "Invited" }}
                        </td>
                    </ng-container>
                
                    <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <div class="glossary-content-container user-management-paginator-box">
                    <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" aria-label="Select page of users">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>