<div class="user-management__icon-box">
  <mat-icon class="user-management__icon-box__icon">
    sync
  </mat-icon>
  <span class="user-management__icon-box__title">Sync clarisa
  </span>
</div>


<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="form-container">
      <div class="sec-1">
        <div class="sec-7">
          <div class="risk-report-button-box">
            <button class="risk-report-button-box__btn btn--chestnut" mat-raised-button color="primary"
              (click)="syncData()">
              <mat-icon class="btn-icon">sync</mat-icon>

              Sync
            </button>
          </div>
        </div>
      </div>

      <div class="table-box mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">


          <ng-container matColumnDef="INIT-ID">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element["code"] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Science programs Name">
            <th mat-header-cell *matHeaderCellDef>Science programs Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element["name"]}}
            </td>
          </ng-container>



          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div class="m">
                <mat-checkbox class="example-margin" (change)="checkInit($event, element.code)"></mat-checkbox>

              </div>
            </td>
          </ng-container>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              No data found.
            </td>
          </tr>
          <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>