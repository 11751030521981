<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ organizationCode != "0" ? "Edit" : "Add" }} Organization
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div> 

<div class="form-dialog-conten-box-1">
  <form [formGroup]="organizationForm" class="form-dialog-content">
    <!-- //Name filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Name<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="name" />
        <mat-error
          class="error"
          *ngIf="organizationForm.controls?.['name']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Acronym filed----- -->
    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Acronym<span class="star">*</span></mat-label
        >
      </div>

      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="acronym" />

        <mat-error
          class="error"
          *ngIf="organizationForm.controls?.['acronym']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Code filed----- -->
    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Code<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="code" type="number" />
        <mat-error
          class="error"
          *ngIf="organizationForm.controls?.['code']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box" align="end">
          <!-- <button mat-button mat-dialog-close>Cancel</button> -->
          <div class="risk-report-button-box">
            <button
              class="risk-report-button-box__btn btn--chestnut"
              type="submit"
              mat-raised-button
              mat-button
              color="primary"
              (click)="submit()"
            >
              {{ organizationCode != "0" ? "Save" : "Add" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <mat-dialog-actions align="end"> -->
<!-- <button mat-button mat-dialog-close>Cancel</button> -->

<!-- </mat-dialog-actions> -->
